import {showHide} from './showHide.js'

export function checkActive(e, params){

	if(e.target.localName != "li") return;
  const attr = e.target.getAttribute('rel');
  if (params.multiple) {
    e.target.classList.toggle(params.active_class)

    const options = params.target.querySelector('option[value="'+attr+'"]')

    if (e.target.classList.contains(params.active_class)) {
      options.setAttribute('selected', 'selected')
      e.target.setAttribute('aria-selected', 'true')
    }else{
      options.removeAttribute('selected')
      e.target.setAttribute('aria-selected', 'false')
    }

  }
  else {
    [...params.body.children].forEach( o => {
      if (e.target != o) {
        o.classList.remove(params.active_class)
        o.setAttribute('aria-selected', 'false')
        o.removeAttribute('selected')
      }else{
        o.classList.add(params.active_class)
        o.setAttribute('aria-selected', 'true')
        o.setAttribute('selected', 'selected')
        params.selected = o
      }
    })

    params.head.innerHTML = e.target.innerHTML
    params.target.value = params.selected.value
    params.target.setAttribute( 'value', params.selected.getAttribute('rel') )

    params.options.forEach( o => e.target.getAttribute('rel') === o.value ? o.setAttribute('selected', 'selected') : o.removeAttribute('selected') )
  }


	showHide(params)
}
