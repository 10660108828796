import {createHead} from './create/head.js'
import {createBody} from './create/body.js'

// --- events
import {dropdownEvents} from './action/events.js'

export function init(args){

  const selects = document.querySelectorAll(args.select);
  if (!selects) return
  selects.forEach( select => {
    const params = {
      'select' : args.select,
      'selectHtml' : select,
      'head'   : args.head,
      'body'   : args.body ? args.body : false,
      'active_class'   : args.active_class,
      'multiple'   :  args.multiple
    }
    if( select.parentNode.classList.contains('abc-select') ) return
    let isBody, isHead;

    if( params.body && params.body != '' ) {
      isBody = select.parentNode.querySelector(params.body)
      if( isBody ) params.body = select.parentNode.querySelector(params.body)
    }
    if( params.head && params.head != '' ) {
      isHead = select.parentNode.querySelector(params.head)
      if( isHead ) params.head = select.parentNode.querySelector(params.head)
    }

    params.selected = select.querySelector('[selected="selected"]')
    params.target = select
    select.parentNode.classList.add('abc-select')

    if (!isHead) createHead(params)
    if (!isBody) createBody(params)

    dropdownEvents(params)

  })

}
