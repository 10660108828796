import {closest} from './../../closest';

import {showHide} from './showHide.js'
import {checkActive} from './checkActive.js'

export function dropdownEvents(params){



  if (!params.body.children) return
  params.width = 0;
  params.width = [...params.body.children].forEach( option => Math.max( params.width, option.clientHeight ) );
  params.height = [...params.body.children].reduce( (tot, num) => tot + num.clientHeight , 0 );
  params.open   = false;
  params.body.style.width = params.width > params.cont.clientWidth ? params.width + "px" : "100%"
  params.body.style.height = 0 + "px"
  //show/hide events
  params.head.addEventListener('click', () => showHide(params) )

  // Close if outside click
  document.addEventListener('click', e => params.open === true && !closest(e.target, ".abc-select", ".abc-select") ? showHide(params) : ''  )
  // document.body.addEventListener('focusin', e => params.open === true && !closest(e.target, ".abc-select", ".abc-select") ? params.target.classList.remove(params.active_class) : ''  )

  params.target.addEventListener('focusin', () => showHide(params) );

  [...params.body.children].forEach( (option, i) => {
    option.addEventListener('focusin', () => {
      params.cont.classList.add(params.active_class);
      params.body.style.height = params.height + 'px'
      params.open = true
    });
    if (i === params.body.children.length - 1) {
      option.addEventListener('focusout', () => {
        params.cont.classList.remove(params.active_class);
        params.body.style.height = 0 + 'px'
        params.open = false
      })
    }

  });

  // 	//compare options
  params.cont.addEventListener('click', e =>  checkActive(e, params) )
  params.cont.addEventListener("keyup", function(e) {
    e.preventDefault();
    if(e.keyCode === 13)  checkActive(e, params)
  });
    params.body.children[params.body.children.length - 1].addEventListener('focusout', e => {
      if(!params.target.classList.contains('isActive')) return
      showHide(params)
    })
}
