import {init} from './../../utils/select/init.js'
import {closest} from './../../utils/closest.js'

function pages(){
  const forms = document.querySelectorAll('.m_form');
  const pages = document.querySelectorAll('.gform_page');
  if(!forms || pages.length === 0) return;

  forms.forEach( form => getPages(form) );

  onLoadPage()

}pages();

function onLoadPage(){
  jQuery(document).on('gform_page_loaded', function(event, form_id, current_page){
    const form = document.querySelector(`#gform_${form_id}`)
    const pages = form.querySelectorAll('.gform_page');
    if (!pages) return
    const steps = form.querySelectorAll('.gf_step');
    pages.forEach( page => {
      if (page.getAttribute('id') === `gform_page_${form_id}_${current_page}`) page.classList.add('gform_page--current')
      else page.classList.remove('gform_page--current')
    })
    steps.forEach( step => {
      if (step.getAttribute('id') === `gf_step_${form_id}_${current_page}`) step.classList.add('gf_step--current')
      else step.classList.remove('gf_step--current')
    })

    const select =  init({
        'select' : '.ginput_container select',
        'head' : 'Select a choice',
        'active_class' : 'isOpen',
        'multiple' : false
      })
  })
}

function getPages(form){

  // Reset all attributes added by gform
  // Add current Classes

  resetSteps(form)
  resetPages(form)

}

function resetSteps(form){
  const steps = form.querySelectorAll('.gf_step');

  if (steps.length === 0) return
  !form.querySelector('.gf_step--current') ? steps[0].classList.add('gf_step--current') : '';
}
function resetPages(form){
  const pages = form.querySelectorAll('.gform_page');
  if (pages.length === 0) return
  pages.forEach( page => {
    page.removeAttribute('style');
  })
  !form.querySelector('.gform_page--current') ? pages[0].classList.add('gform_page--current') : '';
}
function resetBtns(form){
  const btns = [...form.querySelectorAll('.gform_previous_button'), ...form.querySelectorAll('.gform_next_button')];
  btns.forEach( btn => {
    btn.removeAttribute('onclick');
    btn.removeAttribute('onkeypress');
  })
}
