export function createHead(params){
  const isCont = params.cont ? true : false
  const cont = !isCont ? document.createElement('div') : params.cont;
  !isCont ? cont.classList.add('abc-select__container') : ''

  const head = document.createElement('span');
  head.classList.add('abc-select__head');

  const isHead = params.target.querySelector(params.head);

  if (params.selected) head.innerHTML = params.selected.innerHTML
  else if(!params.selected && !isHead && params.head) head.innerHTML = params.head
  else head.innerHTML = params.target.children[0].innerHTML


  params.cont = cont
  params.head = head

  params.cont.appendChild(head);
  isCont ? params.target.parentNode.appendChild(cont) : '';
}
