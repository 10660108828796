import {createOptions} from './options.js'


export function createBody(params){
  const isCont = params.cont ? true : false
  const cont = !isCont ? document.createElement('div') : params.cont;
  !isCont ? cont.classList.add('abc-select__container') : ''

  const body = document.createElement('ul');
  body.classList.add('abc-select__body')
  const selectOptions = params.target.querySelectorAll('option')

  params.cont = cont
  params.body = body
  params.options = selectOptions

  createOptions(params)

  params.cont.appendChild(body);
  isCont ? params.target.parentNode.appendChild(cont) : '';
}
