export function createOptions(params){

  params.options.forEach( option => {


    const li = document.createElement('li');
    li.setAttribute("rel", option.value);
    li.setAttribute("tabindex", "0");
    li.setAttribute("role", "option");
    option.setAttribute("tabindex", "-1");

    li.classList.add('abc-select__item')
    li.innerHTML = option.innerHTML

    if (params.target.value === option.value) {
      li.setAttribute('selected', "selected")
      params.selected = li
    }

    params.body.appendChild(li)

  })

}
